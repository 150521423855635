import { Round } from "../types/QuizGame";
import { AuthenticatedService } from "./AuthenticatedService";

class GameService extends AuthenticatedService {

  public async setGameRound(gameCode: string, round: Round): Promise<void> {
    console.log(`Setting round for quiz game with code ${gameCode} to ${round}`);
    const response = await this.fetchWithAuth(
      `${process.env.REACT_APP_BACKEND_URL}/api/admin/game/${gameCode}/active-round`,
      {
        method: "PUT",
        body: JSON.stringify({ activeRound: round }),
      }
    );
    if (!response.ok) {
      throw new Error(`Error setting quiz game round: ${response.statusText}`);
    }
  }
    
}

export default GameService;

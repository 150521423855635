import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import QuizService from "../services/QuizService";
import { useService } from "../hooks/useService";
import "./QuizDetail.css";
import { PageLayout } from "../components/PageLayout";
import { Quiz } from "../types/Quiz";
import Round369Admin from "../components/Round369Admin";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import RoundOpenDeurAdmin from "../components/RoundOpenDeurAdmin";
import RoundPuzzelAdmin from "../components/RoundPuzzelAdmin";
import RoundGalerijAdmin from "../components/RoundGalerijAdmin";
import RoundCollectiefGeheugenAdmin from "../components/RoundCollectiefGeheugenAdmin";
import RoundFinaleAdmin from "../components/RoundFinaleAdmin";

const QuizDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const quizService = useService(QuizService);

  const [quiz, setQuiz] = useState<Quiz | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [saving, setSaving] = useState(false); // Track save status
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (!quizService || !id) {
      setError("Service not initialized or invalid quiz ID");
      setLoading(false);
      return;
    }

    const fetchQuiz = async () => {
      try {
        const data = await quizService.getQuizById(id);
        setQuiz(data);
      } catch (err) {
        console.error("Error fetching quiz:", err);
        setError("Quiz not found");
      } finally {
        setLoading(false);
      }
    };

    fetchQuiz();
  }, [quizService, id]);

  const handleSave = async () => {
    if (!quiz || !quizService) return;

    setSaving(true);
    try {
      await quizService.updateQuiz(quiz.id, quiz); // Save quiz using QuizService
      navigate("/quizzes");
    } catch (err) {
      console.error("Error saving quiz:", err);
      alert("Failed to save quiz. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!quiz || !quizService) return;

    try {
      await quizService.deleteQuiz(quiz.id);
      navigate("/quizzes");
    } catch (err) {
      console.error("Error deleting quiz:", err);
      alert("Failed to delete quiz. Please try again.");
    }
  };

  if (loading) {
    return (
      <PageLayout protect={true}>
        <div className="quiz-detail-container">
          <h1 className="quiz-detail-title">Loading...</h1>
        </div>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout protect={true}>
        <div className="quiz-detail-container">
          <h1 className="quiz-detail-title">{error}</h1>
          <button
            className="quiz-detail-back-button"
            onClick={() => navigate("/quizzes")}
          >
            Back to Quizzes
          </button>
        </div>
      </PageLayout>
    );
  }

  if (!quiz) {
    return (
      <PageLayout protect={true}>
        <div className="quiz-detail-container">
          <h1 className="quiz-detail-title">Quiz Not Found</h1>
          <button
            className="quiz-detail-back-button"
            onClick={() => navigate("/quizzes")}
          >
            Back to Quizzes
          </button>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout protect={true}>
      <div className="page-header">
        <div className="page-title">
          {quiz.name}
        </div>
        <div className="page-actions">
          <button
            className="btn btn-primary"
            onClick={handleSave}
            disabled={saving} // Disable while saving
          >
            {saving ? "Saving..." : "Save"}
          </button>
          <button
            className="btn btn-success"
            onClick={() => navigate(`/quiz/${id}/play`)}
          >
            Play
          </button>
          <button
            className="btn btn-danger"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </button>
          <button
            className="btn btn-outline-secondary"
            onClick={() => navigate("/quizzes")}
          >
            Back to Quizzes
          </button>
        </div>
      </div>
      <div className="page-main-content">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">3-6-9</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Open deur</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Puzzel</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">Galerij</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth">Collectief Geheugen</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sixth">Finale</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Round369Admin
                    questions={quiz.round_369}
                    onUpdateQuestions={(updatedQuestions) =>
                      setQuiz((prevQuiz) =>
                        prevQuiz ? { ...prevQuiz, round_369: updatedQuestions } : prevQuiz
                      )
                    }
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <RoundOpenDeurAdmin
                    questions={quiz.round_open_deur}
                    onUpdateQuestions={(updatedQuestions) =>
                      setQuiz((prevQuiz) =>
                        prevQuiz ? { ...prevQuiz, round_open_deur: updatedQuestions } : prevQuiz
                      )
                    }
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <RoundPuzzelAdmin
                    questions={quiz.round_puzzel}
                    onUpdateQuestions={(updatedQuestions) =>
                      setQuiz((prevQuiz) =>
                        prevQuiz ? { ...prevQuiz, round_puzzel: updatedQuestions } : prevQuiz
                      )
                    }
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <RoundGalerijAdmin
                    questions={quiz.round_galerij}
                    onUpdateQuestions={(updatedQuestions) =>
                      setQuiz((prevQuiz) =>
                        prevQuiz ? { ...prevQuiz, round_galerij: updatedQuestions } : prevQuiz
                      )
                    }
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="fifth">
                  <RoundCollectiefGeheugenAdmin
                    questions={quiz.round_collectief_geheugen}
                    onUpdateQuestions={(updatedQuestions) =>
                      setQuiz((prevQuiz) =>
                        prevQuiz ? { ...prevQuiz, round_collectief_geheugen: updatedQuestions } : prevQuiz
                      )
                    }
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="sixth">
                  <RoundFinaleAdmin
                    questions={quiz.round_finale}
                    onUpdateQuestions={(updatedQuestions) =>
                      setQuiz((prevQuiz) =>
                        prevQuiz ? { ...prevQuiz, round_finale: updatedQuestions } : prevQuiz
                      )
                    }
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this quiz? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </PageLayout>
  );
};

export default QuizDetail;

import React, { ReactNode } from "react";
import "./PageLayout.css";
import Navbar from "react-bootstrap/Navbar";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import NavigationMenu from "./NavigationMenu";

interface PageLayoutProps {
  children: ReactNode;
  showHeader?: boolean; // Optional prop to enable/disable the header
  protect?: boolean; // Optional prop to enable/disable protection
  className?: string;
}

/**
 * PageLayout component that conditionally renders content based on the `protect` flag.
 * - If `protect` is true, uses AuthenticatedTemplate and UnauthenticatedTemplate for conditional rendering.
 * - If `protect` is false, renders the content and navbar regardless of authentication.
 * @param props - Component props
 */
export const PageLayout: React.FC<PageLayoutProps> = (
  { children, showHeader = true, protect = false, className = "" }
) => {
  if (!protect) {
    // Unprotected content: Render directly without authentication templates
    return (
      <>
        {showHeader && (
          <Navbar bg="primary" variant="dark" className="navbarStyle">
            <NavigationMenu />
            <div className="collapse navbar-collapse justify-content-end">
              <SignInButton />
            </div>
          </Navbar>
        )}
        <div className={`pageContent ${className}`}>{children}</div>
      </>
    );
  }

  // Protected content: Use AuthenticatedTemplate and UnauthenticatedTemplate
  return (
    <>
      <AuthenticatedTemplate>
        {showHeader && (
          <Navbar bg="primary" variant="dark" className="navbarStyle">
            <NavigationMenu />
            <div className="collapse navbar-collapse justify-content-end">
              <SignOutButton />
            </div>
          </Navbar>
        )}
        <div className={`pageContent ${className}`}>{children}</div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div className="loginPrompt">
          <h3>You need to log in to access this content.</h3>
          <SignInButton />
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};

import React, { useEffect, useState } from "react";
import "./Round369Admin.css";
import { Question_369 } from "../types/Quiz";
import { Button, ButtonGroup } from "react-bootstrap";
import MediaSelect from "./MediaSelect";

const defaultQuestions: Question_369[] = Array.from({ length: 12 }, (_, index) => ({
  id: index + 1,
  text: "",
  image: "",
  video: "",
  videoStartTime: 0,
  videoEndTime: 0,
  answer: "",
  isGroupQuestion: false,
  description: "",
}));

interface QuizRound369Props {
  questions?: Question_369[];
  onUpdateQuestions: (updatedQuestions: Question_369[]) => void;
}

const Round369Admin: React.FC<QuizRound369Props> = ({ questions = defaultQuestions, onUpdateQuestions }) => {
  const [localQuestions, setLocalQuestions] = useState<Question_369[]>(defaultQuestions);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [mediaType, setMediaType] = useState<"image" | "video">("image");

  useEffect(() => {
    const adjustedQuestions = [...questions];
    if (adjustedQuestions.length < 12) {
      for (let i = adjustedQuestions.length; i < 12; i++) {
        adjustedQuestions.push({ ...defaultQuestions[i] });
      }
    } else if (adjustedQuestions.length > 12) {
      adjustedQuestions.length = 12;
    }
    setLocalQuestions(adjustedQuestions);
  }, [questions]);

  useEffect(() => {
    const currentQuestion = localQuestions[currentQuestionIndex];
    if (currentQuestion?.video) {
      setMediaType("video");
    } else if (currentQuestion?.image) {
      setMediaType("image");
    }
  }, [currentQuestionIndex, localQuestions]);

  const handleUpdateQuestion = (updatedFields: Partial<Question_369>) => {
    const updatedQuestions = localQuestions.map((q, index) =>
      index === currentQuestionIndex ? { ...q, ...updatedFields } : q
    );
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  const handleMediaTypeChange = (type: "image" | "video") => {
    setMediaType(type);
    handleUpdateQuestion(
      type === "image"
        ? { video: "", videoStartTime: 0, videoEndTime: 0 }
        : { image: "" }
    );
  };

  const handleDurationChange = (duration: number) => {
    const startTime = currentQuestion.videoStartTime || 0;
    const endTime = duration > 0 ? startTime + duration : 0;
    handleUpdateQuestion({ videoEndTime: endTime });
  };

  const navigateQuestion = (direction: number) => {
    setCurrentQuestionIndex((prev) => Math.max(0, Math.min(11, prev + direction)));
  };

  const currentQuestion = localQuestions[currentQuestionIndex] || defaultQuestions[currentQuestionIndex];

  const youtubeEmbedUrl = `https://www.youtube.com/embed/${currentQuestion.video}`;
  const videoParams = [];
  if ((currentQuestion.videoStartTime || 0) > 0) videoParams.push(`start=${currentQuestion.videoStartTime}`);
  if ((currentQuestion.videoEndTime || 0) > 0) videoParams.push(`end=${currentQuestion.videoEndTime}`);
  const videoSrc = videoParams.length > 0 ? `${youtubeEmbedUrl}?${videoParams.join("&")}` : youtubeEmbedUrl;

  return (
    <div className="round-admin drie-zes-negen">
      <div className="question-header">
        <h3>Vraag {currentQuestion?.id || "Onbekend"}</h3>
        <div className="navigation-buttons">
          <Button
            variant="secondary"
            onClick={() => navigateQuestion(-1)}
            disabled={currentQuestionIndex === 0}
          >
            &lt; Vorige Vraag
          </Button>
          <Button
            variant="secondary"
            onClick={() => navigateQuestion(1)}
            disabled={currentQuestionIndex === 11}
          >
            Volgende Vraag &gt;
          </Button>
        </div>
      </div>

      <label className="question-label">
        Vraag:
        <textarea
          className="question-input"
          value={currentQuestion?.text || ""}
          onChange={(e) => handleUpdateQuestion({ text: e.target.value })}
        />
      </label>

      <label className="question-label">
        Antwoord:
        <textarea
          className="question-input"
          value={currentQuestion?.answer || ""}
          onChange={(e) => handleUpdateQuestion({ answer: e.target.value })}
        />
      </label>

      <ButtonGroup className="media-selector">
        <Button variant={mediaType === "image" ? "primary" : "secondary"} onClick={() => handleMediaTypeChange("image")}>Afbeelding</Button>
        <Button variant={mediaType === "video" ? "primary" : "secondary"} onClick={() => handleMediaTypeChange("video")}>Video</Button>
      </ButtonGroup>

      {mediaType === "image" ? (
        <label className="question-label">
          Afbeelding URL:
          <input
            type="text"
            className="question-input"
            value={currentQuestion?.image || ""}
            onChange={(e) => handleUpdateQuestion({ image: e.target.value })}
          />
          <MediaSelect onSelect={mediaItem => handleUpdateQuestion({ image: mediaItem?.url })}/>
        </label>
      ) : (
        <>
          <label className="question-label">
            YouTube Video ID:
            <input
              type="text"
              className="question-input"
              value={currentQuestion?.video || ""}
              onChange={(e) => handleUpdateQuestion({ video: e.target.value })}
            />
          </label>
          <label className="question-label">
            Starttijd (seconden):
            <input
              type="number"
              className="question-input"
              value={(currentQuestion.videoStartTime || 0)}
              onChange={(e) => handleUpdateQuestion({ videoStartTime: Number(e.target.value) })}
            />
          </label>
          <label className="question-label">
            Duur (seconden):
            <input
              type="number"
              className="question-input"
              value={((currentQuestion.videoEndTime || 0) - (currentQuestion.videoStartTime || 0)) || 0}
              onChange={(e) => handleDurationChange(Number(e.target.value))}
            />
          </label>
        </>
      )}

      <div className="media-preview">
        {mediaType === "image" && currentQuestion?.image ? (
          <img src={currentQuestion.image} alt="Preview" style={{ maxWidth: "500px" }} />
        ) : mediaType === "video" && currentQuestion?.video &&
        (
          <iframe
            width="500"
            height="315"
            src={videoSrc}
            title="YouTube Video"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        )
        }
      </div>
    </div>
  );
};

export default Round369Admin;
import React, { useEffect, useRef, useState, useCallback } from "react";
import "./Round369.css";
import { QuizGame } from "../types/QuizGame";
import PlayerStandings from "./PlayerStandings";
import RoundIntroVideo from "./RoundIntroVideo";
import RoundTitleScreen from "./RoundTitleScreen";

interface Round369Props {
  game: QuizGame;
}

const Round369: React.FC<Round369Props> = ({ game }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [lastQuestionIndexWithVideo, setLastQuestionIndexWithVideo] = useState(-1);
  const [currentVideoId, setCurrentVideoId] = useState<string | null>(null);
  const playerRef = useRef<YT.Player | null>(null);
  const youtubeContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    }
  }, []);

  useEffect(() => {
    if (game.activeQuestionStatus === "started" && game.quiz.round_369) {
      const question = game.quiz.round_369[game.activeQuestionIndex];
      if (
        question &&
        question.video &&
        game.activeQuestionIndex !== lastQuestionIndexWithVideo
      ) {
        setLastQuestionIndexWithVideo(game.activeQuestionIndex);
        setCurrentVideoId(question.video);
        setShowVideo(true);
      } else if (!question || !question.video) {
        setShowVideo(false);
      }
    } else {
      cleanupYouTubePlayer();
      setShowVideo(false);
    }
  }, [
    game.activeQuestionStatus,
    game.activeQuestionIndex,
    game.quiz.round_369,
    lastQuestionIndexWithVideo, // Keep in the dependency array for ESLint
  ]);

  const handleYouTubeVideoEnd = useCallback(() => {
    cleanupYouTubePlayer();
    setCurrentVideoId(null);
    setShowVideo(false);
  }, []);

  useEffect(() => {
    if (showVideo && currentVideoId && youtubeContainerRef.current) {
      const question = game.quiz.round_369?.[game.activeQuestionIndex];
      const startSeconds = question?.videoStartTime || 0;
      const endSeconds = question?.videoEndTime || 0;

      // Check if the YT.Player is already created
      if (!playerRef.current && window.YT) {
        playerRef.current = new window.YT.Player(youtubeContainerRef.current, {
          height: "100%",
          width: "100%",
          videoId: currentVideoId,
          playerVars: {
            autoplay: 1,
            controls: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,               // Deprecated, but use for older videos if still relevant
            iv_load_policy: 3,         // Disable annotations
            mute: 0,
            playsinline: 1,
            start: startSeconds,
            end: endSeconds > 0 ? endSeconds : undefined,
          },
          events: {
            onReady: (event) => {
              event.target.playVideo();
            },
            onStateChange: (event) => {
              if (event.data === window.YT.PlayerState.ENDED) {
                handleYouTubeVideoEnd();
              }
            },
          },
        });
      } else if (playerRef.current && typeof playerRef.current.loadVideoById === "function") {
        playerRef.current.loadVideoById({
          videoId: currentVideoId,
          startSeconds,
          endSeconds: endSeconds > 0 ? endSeconds : undefined,
        });
      } else {
        console.error("YT.Player is not initialized properly.");
      }
    }
  }, [showVideo, currentVideoId, handleYouTubeVideoEnd, game.activeQuestionIndex, game.quiz.round_369]);

  useEffect(() => {
    return () => {
      cleanupYouTubePlayer();
    };
  }, []);

  const cleanupYouTubePlayer = () => {
    if (playerRef.current) {
      playerRef.current.destroy();
      playerRef.current = null;
    }
  };

  return (
    <div className="round-container">
      <RoundIntroVideo
        videoSrc="/videos/de-slimste-mens-369.mp4"
        audioSrc="/audio/Bumper.mp3"
      />
      {!game.isRoundStarted ? (
        <RoundTitleScreen game={game} title="3-6-9" />
      ) : (
        <>
          {showVideo && (
            <div className="fullscreen-video-container" ref={youtubeContainerRef}></div>
          )}

          <div className={`round round--369`}>
            <div className="current-question">
              {game.quiz.round_369 && game.activeQuestionIndex >= 0 &&
                game.quiz.round_369[game.activeQuestionIndex].image ? (
                <img
                  src={game.quiz.round_369[game.activeQuestionIndex].image}
                  alt="Current Question"
                />
              ) : (
                <p>No image available</p>
              )}
            </div>

            <div className="separator"></div>

            <div className="overview-wrapper">
              <PlayerStandings
                players={game.players}
                activePlayerTimer_start={game.activePlayerTimer_start}
                activePlayerIndex={game.activePlayerIndex}
              />

              <div className="overview">
                {game.quiz.round_369?.map((_, index) => (
                  <div
                    key={index}
                    className={`question ${index === game.activeQuestionIndex
                      ? "question--active"
                      : index < game.activeQuestionIndex
                        ? "question--scoring"
                        : ""
                      }`}
                  >
                    {index + 1}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Round369;

import React, { useEffect, useState } from "react";
import "./RoundPuzzelAdmin.css";
import { Question_Puzzel } from "../types/Quiz";
import { Accordion, Form } from "react-bootstrap";

const defaultQuestions: Question_Puzzel[] = Array.from({ length: 3 }, (_, index) => ({
  id: index + 1,
  parts: Array.from({ length: 3 }, () => ({
    answer: "",
    pieces: Array.from({ length: 4 }, () => ""),
  })),
}));

interface QuizRoundPuzzelProps {
  questions?: Question_Puzzel[];
  onUpdateQuestions: (updatedQuestions: Question_Puzzel[]) => void;
}

const RoundPuzzelAdmin: React.FC<QuizRoundPuzzelProps> = ({
  questions = defaultQuestions,
  onUpdateQuestions,
}) => {
  const [localQuestions, setLocalQuestions] = useState<Question_Puzzel[]>([]);

  useEffect(() => {
    const adjustedQuestions = [...questions];
    while (adjustedQuestions.length < 3) {
      adjustedQuestions.push({ ...defaultQuestions[adjustedQuestions.length] });
    }
    if (adjustedQuestions.length > 3) adjustedQuestions.length = 3;
    setLocalQuestions(adjustedQuestions);
  }, [questions]);

  const handleInputChange = (
    questionIndex: number,
    partIndex: number,
    field: "answer" | "piece",
    pieceIndex?: number,
    value?: string
  ) => {
    const updatedQuestions = [...localQuestions];
    if (field === "answer") {
      updatedQuestions[questionIndex].parts[partIndex].answer = value || "";
    } else if (field === "piece" && pieceIndex !== undefined) {
      updatedQuestions[questionIndex].parts[partIndex].pieces[pieceIndex] = value || "";
    }
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  return (
    <div className="round-admin puzzel">
      <Accordion defaultActiveKey="0">
        {localQuestions.map((question, questionIndex) => (
          <Accordion.Item key={question.id} eventKey={questionIndex.toString()}>
            <Accordion.Header>Puzzel {question.id}</Accordion.Header>
            <Accordion.Body>
              {question.parts.map((part, partIndex) => (
                <div key={partIndex} className="question-part">
                  <Form.Group className="mb-3">
                    <Form.Label>Answer for Part {partIndex + 1}</Form.Label>
                    <Form.Control
                      type="text"
                      value={part.answer}
                      onChange={(e) =>
                        handleInputChange(
                          questionIndex,
                          partIndex,
                          "answer",
                          undefined,
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                  {part.pieces.map((piece, pieceIndex) => (
                    <Form.Group key={pieceIndex} className="mb-3">
                      <Form.Label>Piece {pieceIndex + 1}</Form.Label>
                      <Form.Control
                        type="text"
                        value={piece}
                        onChange={(e) =>
                          handleInputChange(
                            questionIndex,
                            partIndex,
                            "piece",
                            pieceIndex,
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>
                  ))}
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default RoundPuzzelAdmin;

import React from 'react';
import "./App.css";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './screens/Home';
import Admin from './screens/Admin';
import TermsOfService from './screens/TermsOfService';
import PrivacyPolicy from './screens/PrivacyPolicy';
import QuizList from "./screens/QuizList";
import QuizDetail from "./screens/QuizDetail";
import StartQuizGame from "./screens/StartQuizGame";
import QuizMaster from "./screens/QuizMaster";
import QuizGamePlayers from "./screens/QuizGamePlayers"; // Import the new screen
import MediaScreen from './screens/MediaScreen';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/media" element={<MediaScreen />} />
        <Route path="/quizzes" element={<QuizList />} />
        <Route path="/quizzes/:id" element={<QuizDetail />} />
        <Route path="/quiz/:id/play" element={<StartQuizGame />} />
        <Route path="/quiz-game/:gameCode" element={<QuizMaster />} />
        <Route path="/quiz-game/:gameCode/players" element={<QuizGamePlayers />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

import React, { useEffect, useState, useCallback } from "react";
import "./PlayerStandings.css";
import { PlayerWithScore } from "../types/QuizGame";

interface PlayerStandingsProps {
  players: PlayerWithScore[];
  activePlayerIndex: number;
  activePlayerTimer_start: number; // Start time in epoch
  onTimerExpire?: (playerIndex: number) => void; // Optional
}

const PlayerStandings: React.FC<PlayerStandingsProps> = ({
  players,
  activePlayerIndex,
  activePlayerTimer_start,
  onTimerExpire = () => {}, // Default no-op function
}) => {
  const [currentTime, setCurrentTime] = useState<number>(Date.now());
  const [isTimerExpired, setIsTimerExpired] = useState(false);

  // Calculate remaining time
  const getRemainingTime = useCallback(
    (playerIndex: number) => {
      if (playerIndex === activePlayerIndex && activePlayerTimer_start > 0) {
        const elapsedSeconds = Math.max(
          Math.floor((currentTime - activePlayerTimer_start) / 1000),
          0
        );
        const remainingTime = Math.max(
          players[playerIndex].score - elapsedSeconds,
          0
        );
        return remainingTime;
      }
      return players[playerIndex].score; // Default score if not active
    },
    [activePlayerIndex, activePlayerTimer_start, currentTime, players]
  );

  // Update current time every second when timer starts
  useEffect(() => {
    if (activePlayerTimer_start > 0 && !isTimerExpired) {
      setCurrentTime(Date.now());

      const timer = setInterval(() => {
        setCurrentTime(Date.now());

        // Check if timer has expired
        const remainingTime = getRemainingTime(activePlayerIndex);
        if (remainingTime <= 0) {
          clearInterval(timer);
          setIsTimerExpired(true);
          onTimerExpire(activePlayerIndex); // Notify parent if provided
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [activePlayerTimer_start, activePlayerIndex, getRemainingTime, isTimerExpired, onTimerExpire]);

  return (
    <div className="player-standings">
      {players.map((player, index) => (
        <div
          key={index}
          className={`player-score 
            ${index === activePlayerIndex ? "active" : ""} 
            ${player.isNotInFinals ? "not-in-finals" : ""}`}
        >
          <div className="name">{player.name}</div>
          <div className="score">{getRemainingTime(index)}</div>
        </div>
      ))}
    </div>
  );
};

export default PlayerStandings;

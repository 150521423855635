import React from "react";
import Nav from "react-bootstrap/Nav";

const NavigationMenu: React.FC = () => {
    return (
        <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/media">Media</Nav.Link>
            <Nav.Link href="/quizzes">Quizzes</Nav.Link>
        </Nav>
    );
};

export default NavigationMenu;

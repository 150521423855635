import React, { useEffect, useState } from "react";
import "./RoundGalerijAdmin.css";
import { Question_Galerij, Question_Galerij_Part } from "../types/Quiz";
import { Accordion, Form, Button } from "react-bootstrap";
import MediaSelect from "./MediaSelect";

const defaultQuestions: Question_Galerij[] = Array.from({ length: 3 }, (_, index) => ({
  id: index + 1,
  parts: Array.from({ length: 10 }, (_, partIndex) => ({
    image: "",
    answer: "",
  })),
}));

interface RoundGalerijAdminProps {
  questions?: Question_Galerij[];
  onUpdateQuestions: (updatedQuestions: Question_Galerij[]) => void;
}

const RoundGalerijAdmin: React.FC<RoundGalerijAdminProps> = ({
  questions = defaultQuestions,
  onUpdateQuestions,
}) => {
  const [localQuestions, setLocalQuestions] = useState<Question_Galerij[]>([]);
  const [currentParts, setCurrentParts] = useState<number[]>(Array(3).fill(0));

  useEffect(() => {
    const adjustedQuestions = [...questions];
    if (adjustedQuestions.length < 3) {
      for (let i = adjustedQuestions.length; i < 3; i++) {
        adjustedQuestions.push({ ...defaultQuestions[i] });
      }
    } else if (adjustedQuestions.length > 3) {
      adjustedQuestions.length = 3;
    }
    setLocalQuestions(adjustedQuestions);
  }, [questions]);

  const handleInputChange = (
    questionIndex: number,
    partIndex: number,
    field: keyof Question_Galerij_Part,
    value: string
  ) => {
    const updatedQuestions = [...localQuestions];
    updatedQuestions[questionIndex].parts[partIndex][field] = value;
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  const navigatePart = (questionIndex: number, direction: number) => {
    setCurrentParts((prev) => {
      const newParts = [...prev];
      newParts[questionIndex] = Math.max(
        0,
        Math.min(
          localQuestions[questionIndex].parts.length - 1,
          newParts[questionIndex] + direction
        )
      );
      return newParts;
    });
  };

  return (
    <div className="round-admin galerij">
      <Accordion defaultActiveKey="0">
        {localQuestions.map((question, questionIndex) => {
          const currentPartIndex = currentParts[questionIndex];
          const currentPart = question.parts[currentPartIndex];

          return (
            <Accordion.Item key={question.id} eventKey={questionIndex.toString()}>
              <Accordion.Header>Galerij {question.id}</Accordion.Header>
              <Accordion.Body>
                <div className="image-preview-section">
                  <div className="image-header">
                    <h4>Image {currentPartIndex + 1}</h4>
                    <div className="navigation-buttons">
                      <Button
                        variant="secondary"
                        onClick={() => navigatePart(questionIndex, -1)}
                        disabled={currentPartIndex === 0}
                      >
                        &lt; Vorige Afbeelding
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => navigatePart(questionIndex, 1)}
                        disabled={currentPartIndex === question.parts.length - 1}
                      >
                        Volgende Afbeelding &gt;
                      </Button>
                    </div>
                  </div>
                  <img
                    src={currentPart.image || "https://via.placeholder.com/500x333?text=No+Image"}
                    alt={`Preview ${currentPartIndex + 1}`}
                    className="image-preview"
                    style={{ maxWidth: "500px" }}
                  />
                  <Form.Group
                    controlId={`image-${questionIndex}-${currentPartIndex}`}
                    className="mb-3"
                  >
                    <Form.Label className="image-label">Afbeelding</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Image URL"
                      value={currentPart.image}
                      onChange={(e) =>
                        handleInputChange(
                          questionIndex,
                          currentPartIndex,
                          "image",
                          e.target.value
                        )
                      }
                    />
                    <MediaSelect onSelect={mediaItem => handleInputChange(
                      questionIndex,
                      currentPartIndex,
                      "image",
                      mediaItem && mediaItem.url ? mediaItem.url : ""
                    )} />
                  </Form.Group>
                  <Form.Group
                    controlId={`answer-${questionIndex}-${currentPartIndex}`}
                    className="mb-3"
                  >
                    <Form.Label className="image-label">Antwoord</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Answer"
                      value={currentPart.answer}
                      onChange={(e) =>
                        handleInputChange(
                          questionIndex,
                          currentPartIndex,
                          "answer",
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};

export default RoundGalerijAdmin;

import { useRef, useState } from "react";

interface RoundIntroVideoProps {
    videoSrc: string;
    audioSrc: string;
    onEnd?: () => void;
}

const RoundIntroVideo: React.FC<RoundIntroVideoProps> = ({ videoSrc, audioSrc, onEnd }) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [videoFading, setVideoFading] = useState(true);

    const handleVideoPlay = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const handleVideoEnd = () => {
        if (videoRef.current) {
            videoRef.current.style.transition = "opacity 1.5s ease";
            videoRef.current.style.opacity = "0";
        }
        setTimeout(() => {
            setVideoFading(false);
            if (onEnd) onEnd();
        }, 1500);
    };

    return (
        <>
            {videoFading && (
                <video
                    ref={videoRef}
                    className="fullscreen-video"
                    src={videoSrc}
                    autoPlay
                    onPlay={handleVideoPlay}
                    onEnded={handleVideoEnd}
                />
            )}
            <audio ref={audioRef} src={audioSrc} />
        </>
    );
};

export default RoundIntroVideo;
import React from "react";
import "./RoundGalerij.css";
import { QuizGame } from "../types/QuizGame";
import PlayerStandings from "./PlayerStandings";
import RoundIntroVideo from "./RoundIntroVideo";
import RoundTitleScreen from "./RoundTitleScreen";

interface RoundGalerijProps {
  game: QuizGame;
}

const RoundGalerij: React.FC<RoundGalerijProps> = ({ game }) => {
  return (
    <div className="round-container">
      {/* Intro Video */}
      <RoundIntroVideo
        videoSrc="/videos/de-slimste-mens-galerij.mp4"
        audioSrc="/audio/Bumper.mp3"
      />

      {!game.isRoundStarted ? (
        <RoundTitleScreen game={game} title="Galerij" />
      ) : (
        <>
          {/* Main Content */}
          <div className={`round round--galerij play`}>
            {(
              (game.questionAttempts.length === 1 && game.activeQuestionStatus === "started")
              || game.activeQuestionStatus === "showAnswers"
            )
              && game.activeQuestionIndex >= 0
              && game.activeQuestionAnswerPartIndex >= 0
              ? (
                <div className="question-galerij">
                  <img
                    src={game.quiz.round_galerij[game.activeQuestionIndex].parts[game.activeQuestionAnswerPartIndex].image}
                    alt="Galerij"
                  />
                </div>
              ) : (
                <PlayerStandings
                  players={game.players}
                  activePlayerTimer_start={game.activePlayerTimer_start}
                  activePlayerIndex={game.activePlayerIndex}
                />
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default RoundGalerij;

import React, { useEffect, useState } from "react";
import "./RoundFinaleAdmin.css";
import { Question_Finale } from "../types/Quiz";
import { Accordion, Button } from "react-bootstrap";

const defaultQuestions: Question_Finale[] = Array.from({ length: 10 }, (_, index) => ({
  id: index + 1,
  text: "",
  answers: ["", "", "", "", ""],
}));

interface RoundFinaleAdminProps {
  questions?: Question_Finale[];
  onUpdateQuestions: (updatedQuestions: Question_Finale[]) => void;
}

const RoundFinaleAdmin: React.FC<RoundFinaleAdminProps> = ({
  questions = defaultQuestions,
  onUpdateQuestions,
}) => {
  const [localQuestions, setLocalQuestions] = useState<Question_Finale[]>([]);

  // Ensure at least the first 10 default questions are present
  useEffect(() => {
    const adjustedQuestions = [...questions];
    if (adjustedQuestions.length < 10) {
      for (let i = adjustedQuestions.length; i < 10; i++) {
        adjustedQuestions.push({ ...defaultQuestions[i] });
      }
    }
    setLocalQuestions(adjustedQuestions);
  }, [questions]);

  // Handle updating a question's text
  const handleUpdateQuestion = (id: number, updatedFields: Partial<Question_Finale>) => {
    const updatedQuestions = localQuestions.map((q) =>
      q.id === id ? { ...q, ...updatedFields } : q
    );
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  // Handle updating a specific answer
  const handleUpdateAnswer = (questionId: number, answerIndex: number, value: string) => {
    const updatedQuestions = localQuestions.map((q) =>
      q.id === questionId
        ? {
            ...q,
            answers: q.answers.map((ans, idx) => (idx === answerIndex ? value : ans)),
          }
        : q
    );
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  // Handle adding a new question
  const handleAddQuestion = () => {
    const newQuestion: Question_Finale = {
      id: localQuestions.length + 1,
      text: "",
      answers: ["", "", "", "", ""],
    };
    const updatedQuestions = [...localQuestions, newQuestion];
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  // Handle deleting a question (only for questions beyond the first 10)
  const handleDeleteQuestion = (id: number) => {
    if (id <= 10) return; // Prevent deleting the first 10 questions

    const updatedQuestions = localQuestions.filter((q) => q.id !== id);
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  return (
    <div className="round-admin finale">
      <Accordion defaultActiveKey="0">
        {localQuestions.map((question, index) => (
          <Accordion.Item key={question.id} eventKey={index.toString()}>
            <Accordion.Header>
              Vraag {question.id}: {question.text || "Geen tekst"}
            </Accordion.Header>
            <Accordion.Body>
              <label className="question-label">
                Vraag:
                <textarea
                  className="question-input"
                  value={question.text}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { text: e.target.value })
                  }
                />
              </label>
              <div className="answers-section">
                <label className="question-label">Antwoorden:</label>
                {question.answers.map((answer, answerIndex) => (
                  <div key={answerIndex} className="answer-input">
                    <input
                      type="text"
                      className="question-input"
                      value={answer}
                      placeholder={`Antwoord ${answerIndex + 1}`}
                      onChange={(e) =>
                        handleUpdateAnswer(question.id, answerIndex, e.target.value)
                      }
                    />
                  </div>
                ))}
              </div>

              {question.id > 10 && (
                <Button
                  variant="danger"
                  className="mt-3"
                  onClick={() => handleDeleteQuestion(question.id)}
                >
                  Verwijder Vraag
                </Button>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      <Button variant="primary" className="mt-4" onClick={handleAddQuestion}>
        Voeg Vraag Toe
      </Button>
    </div>
  );
};

export default RoundFinaleAdmin;

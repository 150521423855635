import React, { useEffect, useRef, useState, useCallback } from "react";
import "./RoundCollectiefGeheugen.css";
import { QuizGame } from "../types/QuizGame";
import PlayerStandings from "./PlayerStandings";
import RoundIntroVideo from "./RoundIntroVideo";
import RoundTitleScreen from "./RoundTitleScreen";

interface RoundCollectiefGeheugenProps {
  game: QuizGame;
}

const RoundCollectiefGeheugen: React.FC<RoundCollectiefGeheugenProps> = ({ game }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState<string | null>(null);

  const playedVideos = useRef<Set<string>>(new Set());
  const playerRef = useRef<YT.Player | null>(null);
  const youtubeContainerRef = useRef<HTMLDivElement | null>(null);

  // Load YouTube Iframe API
  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    }
  }, []);

  // Watch for Question Index Changes
  useEffect(() => {
    if (game.activeQuestionIndex !== -1 && game.quiz.round_collectief_geheugen) {
      const question = game.quiz.round_collectief_geheugen[game.activeQuestionIndex];
      if (question && question.video && !playedVideos.current.has(question.video)) {
        setCurrentVideoId(question.video);
        setShowVideo(true);
      }
    } else {
      cleanupYouTubePlayer();
      setShowVideo(false); // Hide video when no question is active
    }
  }, [game.activeQuestionIndex, game.quiz.round_collectief_geheugen]);

  const handleYouTubeVideoEnd = useCallback(() => {
    if (currentVideoId) {
      playedVideos.current.add(currentVideoId); // Mark video as played
    }
    cleanupYouTubePlayer();
    setCurrentVideoId(null);
    setShowVideo(false); // Trigger React DOM update
  }, [currentVideoId]);

  // Initialize YouTube Player After ShowVideo Becomes True
  useEffect(() => {
    if (showVideo && currentVideoId && youtubeContainerRef.current) {
      const question = game.quiz.round_collectief_geheugen?.[game.activeQuestionIndex];
      const startSeconds = question?.videoStartTime || 0;
      const endSeconds = question?.videoEndTime || 0;

      if (!playerRef.current && window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(youtubeContainerRef.current, {
          height: "100%",
          width: "100%",
          videoId: currentVideoId,
          playerVars: {
            autoplay: 1,
            controls: 1,
            modestbranding: 1,
            rel: 0,
            mute: 0,
            playsinline: 1,
            start: startSeconds,
            end: endSeconds > 0 ? endSeconds : undefined,
          },
          events: {
            onReady: (event) => event.target.playVideo(),
            onStateChange: (event) => {
              if (event.data === window.YT.PlayerState.ENDED) {
                handleYouTubeVideoEnd();
              }
            },
          },
        });
      } else if (playerRef.current && typeof playerRef.current.loadVideoById === "function") {
        playerRef.current.loadVideoById({
          videoId: currentVideoId,
          startSeconds,
          endSeconds: endSeconds > 0 ? endSeconds : undefined,
        });
      }
    }
  }, [showVideo, currentVideoId, handleYouTubeVideoEnd, game.activeQuestionIndex, game.quiz.round_collectief_geheugen]);

  // Cleanup on Component Unmount
  useEffect(() => {
    return () => {
      cleanupYouTubePlayer();
    };
  }, []);

  const cleanupYouTubePlayer = () => {
    if (playerRef.current) {
      playerRef.current.destroy(); // Destroy YouTube player safely
      playerRef.current = null; // Clear the reference
    }
  };

  return (
    <div className="round-container">
      <RoundIntroVideo
        videoSrc="/videos/de-slimste-mens-collectiefgeheugen.mp4"
        audioSrc="/audio/Bumper.mp3"
      />
      {!game.isRoundStarted ? (
        <RoundTitleScreen game={game} title="Het Collectieve Geheugen" />
      ) : (
        <>
          {showVideo && (
            <div className="fullscreen-video-container" ref={youtubeContainerRef}></div>
          )}
          <div className={`round round--collectiefgeheugen play`}>
            <div className="question-answers">
              {game.activeQuestionAnswerParts?.map((answerPart, index) => (
                <div
                  key={index}
                  className={`answer-part ${answerPart.isAnswered ? "answered" : ""}`}
                >
                  <div className="answer-part__score">{answerPart.score}</div>
                  <div className="answer-part__answer">{answerPart.answer}</div>
                </div>
              ))}
            </div>
            <div className="separator"></div>
            <PlayerStandings
              players={game.players}
              activePlayerTimer_start={game.activePlayerTimer_start}
              activePlayerIndex={game.activePlayerIndex}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default RoundCollectiefGeheugen;

import React, { useState, useEffect } from "react";
import "./RoundPuzzel.css";
import { QuizGame } from "../types/QuizGame";
import PlayerStandings from "./PlayerStandings";
import RoundIntroVideo from "./RoundIntroVideo";
import RoundTitleScreen from "./RoundTitleScreen";

interface RoundPuzzelProps {
  game: QuizGame;
}

const RoundPuzzel: React.FC<RoundPuzzelProps> = ({ game }) => {
  const [currentQuestionId, setCurrentQuestionId] = useState<number | null>(null);
  const [shuffledPieces, setShuffledPieces] = useState<JSX.Element[]>([]);

  const shuffleArray = (array: any[]) => {
    return [...array].sort(() => Math.random() - 0.5);
  };

  const activeQuestion = game.quiz.round_puzzel?.[game.activeQuestionIndex];

  useEffect(() => {
    if (activeQuestion && activeQuestion.id !== currentQuestionId) {
      const shuffled = shuffleArray(
        activeQuestion.parts.flatMap((part, partIndex) =>
          part.pieces.map((piece, pieceIndex) => (
            <div
              key={`${activeQuestion.id}-${partIndex}-${pieceIndex}`}
              className={`puzzle-piece answer-part-${partIndex}`}
              data-part-index={partIndex}
            >
              {piece}
            </div>
          ))
        )
      );

      setShuffledPieces(shuffled);
      setCurrentQuestionId(activeQuestion.id);
    }
  }, [activeQuestion, currentQuestionId]);

  const renderShuffledPieces = () =>
    shuffledPieces.map((piece) => {
      const partIndex = parseInt(piece.props["data-part-index"]);
      const isAnswered = game.activeQuestionAnswerParts?.[partIndex]?.isAnswered;
      return React.cloneElement(piece, {
        className: `${piece.props.className} ${isAnswered ? "done" : ""}`,
      });
    });

  return (
    <div className="round-container">
      <RoundIntroVideo
        videoSrc="/videos/de-slimste-mens-puzzel.mp4"
        audioSrc="/audio/Bumper.mp3"
      />

      {!game.isRoundStarted ? (
        <RoundTitleScreen game={game} title="Puzzel" />
      ) : (
        <div className="round round--puzzel play">
          <div className="question-puzzle">
            {(game.activeQuestionStatus === "started" || game.activeQuestionStatus === "showAnswers") 
            && renderShuffledPieces()}
          </div>

          <div className="question-answers">
            {(game.activeQuestionStatus === "started" || game.activeQuestionStatus === "showAnswers") 
            && game.activeQuestionAnswerParts?.map((answerPart, index) => (
                <div
                  key={index}
                  className={`answer-part answer-part-${index} ${
                    answerPart.isAnswered ? "answered" : ""
                  }`}
                >
                  <div className="answer-part__score">30</div>
                  <div className="answer-part__answer">{answerPart.answer}</div>
                </div>
              ))}
          </div>

          <PlayerStandings
            players={game.players}
            activePlayerTimer_start={game.activePlayerTimer_start}
            activePlayerIndex={game.activePlayerIndex}
          />
        </div>
      )}
    </div>
  );
};

export default RoundPuzzel;

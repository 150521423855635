import { AuthenticatedService } from "./AuthenticatedService";

export interface MediaItem {
  name: string;
  type: "file" | "folder";
  url?: string; // Add URL support for files
}

class MediaService extends AuthenticatedService {
  private apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/admin/media`;

  // List files and folders
  public async listMedia(prefix: string = ""): Promise<MediaItem[]> {
    const response = await this.fetchWithAuth(`${this.apiUrl}/list?prefix=${encodeURIComponent(prefix)}`);
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(`Error listing media: ${response.statusText}`);
    }
  }

  // Upload a file
  public async uploadMedia(fileName: string, fileContent: string, prefix: string = ""): Promise<string> {
    // Sanitize both prefix and fileName
    const sanitizedPrefix = prefix.replace(/\/+$/, "");  // Remove trailing slashes
    const sanitizedFileName = fileName.replace(/^\/+/, "");  // Remove leading slashes

    const fullPath = sanitizedPrefix ? `${sanitizedPrefix}/${sanitizedFileName}` : sanitizedFileName;

    const response = await this.fetchWithAuth(`${this.apiUrl}/upload`, {
      method: "POST",
      body: JSON.stringify({
        fileName: fullPath, // Corrected full path
        fileContent,
      }),
    });

    if (response.ok) {
      const result = await response.json();
      return result.url; // Return the public URL after upload
    } else {
      throw new Error(`Error uploading media: ${response.statusText}`);
    }
  }

  // Create Folder API Call
  public async createFolder(folderName: string, prefix: string = ""): Promise<void> {
    const response = await this.fetchWithAuth(`${this.apiUrl}/create-folder`, {
      method: "POST",
      body: JSON.stringify({
        folderName,
        prefix,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error creating folder: ${response.statusText}`);
    }
  }

  // Delete a file
  public async deleteMedia(fileName: string, prefix: string = ""): Promise<void> {
    const response = await this.fetchWithAuth(`${this.apiUrl}/delete/${encodeURIComponent(`${prefix}${fileName}`)}`, {
      method: "DELETE",
    });
    if (!response.ok) {
      throw new Error(`Error deleting media: ${response.statusText}`);
    }
  }
}

export default MediaService;

import React from "react";
import "./RoundFinale.css";
import logo from "../images/de-slimste-mens-ter-wereld-logo.png";
import { QuizGame } from "../types/QuizGame";
import PlayerStandings from "./PlayerStandings";
import RoundIntroVideo from "./RoundIntroVideo";
import RoundTitleScreen from "./RoundTitleScreen";

interface RoundFinaleProps {
  game: QuizGame;
  onPlayerTimeExpired: (playerIndex: number) => void; // New callback prop
}

const RoundFinale: React.FC<RoundFinaleProps> = ({ game, onPlayerTimeExpired }) => {
  return (
    <div className="round-container">
      <RoundIntroVideo
        videoSrc="/videos/de-slimste-mens-finale.mp4"
        audioSrc="/audio/Bumper.mp3"
      />

      {!game.isRoundStarted ? (
          <RoundTitleScreen game={game} title="Finale" />
      ) : game.isGameFinished ? (
          <div className="endscreen">
            <div className="endcredits">
              <img
                src={logo}
                alt="End credits"
              />
            </div>
            <div className="winner">{game.winner}</div>
          </div>
        ) : (
        <div className={`round round--finale play`}>
          <div className="question-answers">
            {game.activeQuestionAnswerParts?.map((answerPart, index) => (
              <div
                key={index}
                className={`answer-part ${
                  answerPart.isAnswered ? "answered" : ""
                }`}
              >
                <div className="answer-part__score">{answerPart.score}</div>
                <div className="answer-part__answer">{answerPart.answer}</div>
              </div>
            ))}
          </div>
          <div className="separator"></div>
          <PlayerStandings
            players={game.players}
            activePlayerTimer_start={game.activePlayerTimer_start}
            activePlayerIndex={game.activePlayerIndex}
            onTimerExpire={onPlayerTimeExpired} // Pass the callback here
          />
        </div>
      )}
    </div>
  );
};

export default RoundFinale;

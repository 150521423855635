import React from "react";
import "./RoundTitleScreen.css";
import PlayerStandings from "./PlayerStandings";
import { QuizGame } from "../types/QuizGame";

interface RoundOpenDeurProps {
    game: QuizGame;
    title: string;
}

const RoundTitleScreen: React.FC<RoundOpenDeurProps> = ({ game, title }) => {
    return (
        <div className={`round-title-wrapper`}>
            <div className="round-title">{title}</div>
            <div className="separator"></div>
            <PlayerStandings
                players={game.players}
                activePlayerTimer_start={game.activePlayerTimer_start}
                activePlayerIndex={game.activePlayerIndex}
            />
        </div>
    );
};

export default RoundTitleScreen;

import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import { Action, QuizGame, Round } from "../types/QuizGame";
import "./QuizMaster.css";  // Import CSS file
import { useService } from "../hooks/useService";
import GameService from "../services/GameService";
import { Button, Form, Modal } from "react-bootstrap";
import { PageLayout } from "../components/PageLayout";
import PlayerStandings from "../components/PlayerStandings";

const QuizMaster: React.FC = () => {
  const { gameCode } = useParams<{ gameCode: string }>();
  const [game, setGame] = useState<QuizGame | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedRound, setSelectedRound] = useState<Round | undefined>(undefined);
  const [showRoundModal, setShowRoundModal] = useState(false);

  const socket = useRef<Socket | null>(null);
  const gameService = useService(GameService);

  useEffect(() => {
    if (!gameCode) return;

    if (!socket.current) {
      socket.current = io(process.env.REACT_APP_BACKEND_URL as string);
    }

    console.log(`QuizMaster joining game ${gameCode}`);

    socket.current.emit("join-game", gameCode, (response: { error?: string; game?: QuizGame }) => {
      if (response.error) {
        setError(response.error);
        return;
      }
      if (response.game) {
        setGame(response.game);
        setSelectedRound(response.game.activeRound); // Set initial active round
      }
    });

    socket.current.on("game-state-changed", (updatedGame: QuizGame) => {
      console.log("Game state updated:", updatedGame);
      setGame(updatedGame);
      setSelectedRound(updatedGame.activeRound); // Sync round change
    });

    return () => {
      console.log("Disconnecting socket...");
      socket.current?.disconnect();
      socket.current = null;
    };
  }, [gameCode]);

  const handleAction = (action: Action) => {
    if (!gameCode || !socket.current) return;

    socket.current.emit("update-game", { gameCode, action }, (response: { error?: string }) => {
      if (response.error) {
        setError(response.error);
      }
    });
  };

  const handleRoundChange = async () => {
    if (!gameCode || !selectedRound) return;
    if (!gameService) {
      setError("Service not initialized");
      return;
    }

    try {
      await gameService.setGameRound(gameCode, selectedRound);
      console.log(`Active round set to ${selectedRound}`);
    } catch (error) {
      console.error("Error setting game round:", error);
      setError("Failed to change the round.");
    }
  };

  const roundOptions: Round[] = [
    "369",
    "open_deur",
    "puzzel",
    "galerij",
    "collectief_geheugen",
    "finale",
  ];

  const getGameRoundName = (): string => {
    switch (game?.activeRound) {
      case "369":
        return "3-6-9";
      case "open_deur":
        return "Open Deur";
      case "puzzel":
        return "Puzzel";
      case "galerij":
        return "Galerij";
      case "collectief_geheugen":
        return "Collectief Geheugen";
      case "finale":
        return "Finale";
      default:
        return "";
    }
  }

  if (error) return <p className="error">Error: {error}</p>;
  if (!game) return <p className="loading">Loading...</p>;

  const activeQuestion =
    game.activeRound === "369"
      ? game.quiz.round_369?.[game.activeQuestionIndex]
      : game.activeRound === "open_deur"
        ? game.quiz.round_open_deur?.[game.activeQuestionIndex]
        : game.activeRound === "puzzel"
          ? { text: `Puzzel ${game.activeQuestionIndex + 1}` }
          : game.activeRound === "collectief_geheugen"
            ? { text: `Video ${game.activeQuestionIndex + 1}` }
            : game.activeRound === "finale"
              ? game.quiz.round_finale?.[game.activeQuestionIndex]
              : undefined;

  return (
    <PageLayout showHeader={false} protect={true} className="quiz-master-screen" >
      <div className="quiz-master">
        <h1 className="round-title" onClick={() => setShowRoundModal(true)}>{getGameRoundName()}</h1>

        {/* Modal for changing the round */}
        <Modal show={showRoundModal} onHide={() => setShowRoundModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Change Active Round</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="roundSelect">
                <Form.Label>Select Round</Form.Label>
                <Form.Select
                  value={selectedRound || ""}
                  onChange={(e) => setSelectedRound(e.target.value as Round)}
                >
                  <option value="">Select a round...</option>
                  {roundOptions.map((round) => (
                    <option key={round} value={round}>
                      {round.replace("_", " ").toUpperCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowRoundModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleRoundChange} disabled={!selectedRound}>
              Set Active Round
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Show Players and Scores */}
        <PlayerStandings activePlayerIndex={game.activePlayerIndex}
          activePlayerTimer_start={game.activePlayerTimer_start}
          players={game.players}
        />

        {/* Show Active Question */}
        <div className="question-admin">
          {activeQuestion && (
            <>
              <div className="question-admin__question">
                <div className="label">Vraag</div>
                <div className="value">{activeQuestion.text}</div>
              </div>
              { "answer" in activeQuestion && (
                <div className="question-admin__answer">
                  <div className="label">Antwoord</div>
                  <div className="value">{activeQuestion.answer}</div>
                </div>
              )}
            </>
          )}

          {game.activeRound === "galerij"
            && game.activeQuestionIndex >= 0
            && game.activeQuestionAnswerPartIndex >= 0
            && game.questionAttempts.length === 1
            && (
              <>
                {game.activeQuestionAnswerParts?.[game.activeQuestionAnswerPartIndex].answer}
              </>
            )}
          {(game.activeRound === "open_deur"
            || game.activeRound === "puzzel"
            || (game.activeRound === "galerij" && game.questionAttempts.length > 1)
            || game.activeRound === "collectief_geheugen"
            || game.activeRound === "finale")
            && game.activeQuestionAnswerParts
            && (
              <ul className="answer-parts">
                {game.activeQuestionAnswerParts.map((answerPart, index) => (
                  <li
                    key={index}
                    className={`answer-part ${answerPart.isAnswered ? "answered" : ""}`}
                  >
                    {answerPart.answer}
                    {game.allowedActions.includes("correct") && !answerPart.isAnswered && (
                      <button
                        onClick={() => handleAction({ type: "correct", targetIndex: index })}
                        className="btn btn-success"
                      >
                        Correct
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            )}

          {/* Other Allowed Actions */}
          <div className="actions">
            {game.allowedActions
              .filter((action) => 
                (action !== "selectQuestion")
              && (action !== "correct" || game.activeRound === "369" 
                || (game.activeRound === "galerij" && game.questionAttempts.length === 1))
            )
              .map((action) => (
                <button
                  key={action}
                  onClick={() => handleAction({ type: action })}
                  className={`btn btn-primary action--${action}`}
                >
                  {action}
                </button>
              ))}
          </div>

          {/* Select Questions */}
          {game.allowedActions.includes("selectQuestion") && game.activeRound === "open_deur" && (
            <>
              <h3>Select a Question</h3>
              <ul className="select-questions">
                {game.quiz.round_open_deur?.map((question, index) => {
                  const isAlreadySelected = game.selectedQuestionsInRound.includes(index);

                  return (
                    <li key={index} className={isAlreadySelected ? "already-selected" : ""}>
                      {question.text}

                      {/* Show "Select" button only for unselected questions */}
                      {!isAlreadySelected && (
                        <button
                          onClick={() =>
                            handleAction({ type: "selectQuestion", targetIndex: index })
                          }
                          className="btn btn-warning"
                        >
                          Select
                        </button>
                      )}
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>

      </div>
    </PageLayout>
  );
};

export default QuizMaster;

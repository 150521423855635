import React, { useState } from "react";
import MediaBrowser from "./MediaBrowser";
import { Modal, Button } from "react-bootstrap";
import { MediaItem } from "../services/MediaService";
import "./MediaSelect.css"; // Import the custom CSS for styling

interface MediaSelectProps {
  onSelect: (selectedFile?: MediaItem) => void;
}

const MediaSelect: React.FC<MediaSelectProps> = ({ onSelect }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<MediaItem | null>(null);

  const handleSelect = () => {
    if (selectedFile) {
      onSelect(selectedFile);
      setShowModal(false); // Close modal after confirming selection
    }
  };

  const handleClose = () => {
    setSelectedFile(null); // Clear selected file when modal is closed
    setShowModal(false);
  };

  return (
    <div>
      <button
        type="button"
        className="btn-link" 
        onClick={() => setShowModal(true)}>
        Select from media library
      </button>
      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="media-select-modal" // Custom class for the modal
      >
        <Modal.Header closeButton>
          <Modal.Title>Select an Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MediaBrowser
            mode="select"
            onSelect={(file) => setSelectedFile(file ? file : null)} // Update state for the selected file
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSelect}
            disabled={!selectedFile} // Disable the button if no file is selected
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MediaSelect;

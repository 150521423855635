import React from "react";
import MediaBrowser from "../components/MediaBrowser";
import { PageLayout } from "../components/PageLayout";
import "./MediaScreen.css";

const MediaScreen: React.FC = () => {
  return (
    <PageLayout protect={true}>
      <div className="media-screen-content">
        <h1>Media Manager</h1>
        <MediaBrowser />
      </div>
    </PageLayout>
  );
};

export default MediaScreen;

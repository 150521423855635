import React, { useEffect, useRef, useState, useCallback } from "react";
import "./RoundOpenDeur.css";
import { QuizGame } from "../types/QuizGame";
import PlayerStandings from "./PlayerStandings";
import RoundIntroVideo from "./RoundIntroVideo";
import RoundTitleScreen from "./RoundTitleScreen";

interface RoundOpenDeurProps {
  game: QuizGame;
}

const RoundOpenDeur: React.FC<RoundOpenDeurProps> = ({ game }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState<string | null>(null);

  const playedVideos = useRef<Set<string>>(new Set());
  const playerRef = useRef<YT.Player | null>(null);
  const youtubeContainerRef = useRef<HTMLDivElement | null>(null);

  // Load YouTube Iframe API
  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    }
  }, []);

  // Watch for Question Index Changes
  useEffect(() => {
    if (game.activeQuestionIndex !== -1 && game.quiz.round_open_deur) {
      const question = game.quiz.round_open_deur[game.activeQuestionIndex];
      if (question && question.video && !playedVideos.current.has(question.video)) {
        setCurrentVideoId(question.video);
        setShowVideo(true);
      }
    } else {
      cleanupYouTubePlayer();
      setShowVideo(false); // Hide video when no question is active
    }
  }, [game.activeQuestionIndex, game.quiz.round_open_deur]);

  const handleYouTubeVideoEnd = useCallback(() => {
    if (currentVideoId) {
      playedVideos.current.add(currentVideoId); // Mark video as played
    }
    cleanupYouTubePlayer();
    setCurrentVideoId(null);
    setShowVideo(false); // Trigger React DOM update
  }, [currentVideoId]);

  // Initialize YouTube Player After ShowVideo Becomes True
  useEffect(() => {
    if (showVideo && currentVideoId && youtubeContainerRef.current) {
      const question = game.quiz.round_open_deur?.[game.activeQuestionIndex];
      const startSeconds = question?.videoStartTime || 0;
      const endSeconds = question?.videoEndTime || 0;

      if (!playerRef.current && window.YT) {
        playerRef.current = new window.YT.Player(youtubeContainerRef.current, {
          height: "100%",
          width: "100%",
          videoId: currentVideoId,
          playerVars: {
            autoplay: 1,
            controls: 1,
            modestbranding: 1,
            rel: 0,
            mute: 0,
            playsinline: 1,
            start: startSeconds,
            end: endSeconds > 0 ? endSeconds : undefined,
          },
          events: {
            onReady: (event) => event.target.playVideo(),
            onStateChange: (event) => {
              if (event.data === window.YT.PlayerState.ENDED) {
                handleYouTubeVideoEnd();
              }
            },
          },
        });
      } else if (playerRef.current) {
        playerRef.current.loadVideoById(currentVideoId);
      }
    }
  }, [showVideo, currentVideoId, handleYouTubeVideoEnd, game.activeQuestionIndex, game.quiz.round_open_deur]);

  // Cleanup on Component Unmount
  useEffect(() => {
    return () => {
      cleanupYouTubePlayer();
    };
  }, []);

  const cleanupYouTubePlayer = () => {
    if (playerRef.current) {
      playerRef.current.destroy(); // Destroy YouTube player safely
      playerRef.current = null; // Clear the reference
    }
  };

  return (
    <div className="round-container">
      {/* Intro Video */}
      <RoundIntroVideo
        videoSrc="/videos/de-slimste-mens-opendeur.mp4"
        audioSrc="/audio/Bumper.mp3"
      />
      {!game.isRoundStarted ? (
        <RoundTitleScreen game={game} title="Open Deur" />
      ) : (
        <>
          {/* Fullscreen YouTube Video */}
          {showVideo && (
            <div
              className="fullscreen-video-container"
              ref={youtubeContainerRef}
            ></div>
          )}

          {/* Main Content */}
          {game.activeQuestionIndex === -1 ? (
            <div className={`round round--opendeur select`}>
              <PlayerStandings
                players={game.players}
                activePlayerTimer_start={game.activePlayerTimer_start}
                activePlayerIndex={game.activePlayerIndex}
              />
              <div className="separator"></div>

              <div className="question-selector">
                {game.quiz.round_open_deur?.map((question, index) => (
                  <div
                    key={index}
                    className={`question ${game.selectedQuestionsInRound.includes(index)
                        ? "selected"
                        : ""
                      }`}
                  >
                    <img
                      src={`https://img.youtube.com/vi/${question.video}/hqdefault.jpg`}
                      alt={`Question ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className={`round round--opendeur play`}>
              <div className="question-answers">
                {game.activeQuestionAnswerParts?.map((answerPart, index) => (
                  <div
                    key={index}
                    className={`answer-part ${answerPart.isAnswered ? "answered" : ""
                      }`}
                  >
                    <div className="answer-part__score">{answerPart.score}</div>
                    <div className="answer-part__answer">
                      {answerPart.answer}
                    </div>
                  </div>
                ))}
              </div>
              <div className="separator"></div>
              <PlayerStandings
                players={game.players}
                activePlayerTimer_start={game.activePlayerTimer_start}
                activePlayerIndex={game.activePlayerIndex}
              />
            </div>
          )}
        </>
      )}

    </div>
  );
};

export default RoundOpenDeur;

import React, { useState, useEffect, useCallback } from "react";
import MediaService, { MediaItem } from "../services/MediaService";
import "./MediaBrowser.css";
import { useService } from "../hooks/useService";

interface MediaBrowserProps {
  mode?: "standalone" | "select";
  onSelect?: (selectedFile?: MediaItem) => void;
}

const isValidName = (name: string) => {
  const invalidChars = /["\\/:<>|?*]/;
  return name.length > 0 && !invalidChars.test(name);
};

const MediaBrowser: React.FC<MediaBrowserProps> = ({ mode = "standalone", onSelect }) => {
  const [error, setError] = useState<string | null>(null);
  const [currentPath, setCurrentPath] = useState<string>(""); // Current folder path
  const [contents, setContents] = useState<MediaItem[]>([]); // Items in the current folder
  const [selectedFile, setSelectedFile] = useState<MediaItem | null>(null);

  const mediaService = useService(MediaService);

  const fetchContents = useCallback(
    async (path: string) => {
      if (!mediaService) {
        setError("Service not initialized");
        return;
      }
      try {
        const items = await mediaService.listMedia(path);

        // Filter out .empty placeholder files
        const filteredItems = items.filter((item) => item.name !== ".empty");

        const sortedItems = filteredItems.sort((a, b) => {
          if (a.type !== b.type) return a.type === "folder" ? -1 : 1;
          return a.name.localeCompare(b.name);
        });

        setContents(sortedItems);
      } catch (error) {
        setError(`Error fetching contents: ${error}`);
      }
    },
    [mediaService]
  );

  useEffect(() => {
    fetchContents(currentPath);
  }, [currentPath, fetchContents]);

  const handlePathClick = (index: number) => {
    const newPath =
      index === -1
        ? ""
        : currentPath.split("/").slice(0, index + 1).join("/");
    setCurrentPath(newPath ? newPath + "/" : "");
    setSelectedFile(null); // Clear selected file on folder change
    if (mode === "select" && onSelect) {
      onSelect(undefined); // Immediately call the onSelect callback with no file
    }
  };

  const handleFolderClick = (folderName: string) => {
    const newPath = `${currentPath}${folderName}/`;
    setCurrentPath(newPath);
    setSelectedFile(null); // Clear selected file on folder change
    if (mode === "select" && onSelect) {
      onSelect(undefined); // Immediately call the onSelect callback with no file
    }
  };

  const handleFileClick = (item: MediaItem) => {
    if (item.type === "file") {
      setSelectedFile(item);
      if (mode === "select" && onSelect) {
        onSelect(item); // Immediately call the onSelect callback
      }
    }
  };

  const handleDelete = async (fileName: string) => {
    if (!mediaService) {
      setError("Service not initialized");
      return;
    }

    const confirmed = window.confirm(
      `Are you sure you want to delete ${fileName}?`
    );
    if (!confirmed) return;

    try {
      await mediaService.deleteMedia(fileName, currentPath);
      fetchContents(currentPath);
    } catch (error) {
      setError(`Error deleting file: ${error}`);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return;
    if (!mediaService) {
      setError("Service not initialized");
      return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async () => {
      try {
        if (typeof reader.result === "string") {
          await mediaService.uploadMedia(
            file.name,
            reader.result.split(",")[1],
            currentPath
          );
          fetchContents(currentPath);
          alert("File uploaded successfully!");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload file.");
      }
    };

    reader.readAsDataURL(file);
  };

  const handleCreateFolder = async () => {
    if (!mediaService) {
      setError("Service not initialized");
      return;
    }
    const folderName = prompt("Enter new folder name:");
    if (!folderName || !isValidName(folderName)) {
      alert("Invalid folder name. Avoid special characters.");
      return;
    }

    try {
      await mediaService.createFolder(folderName, currentPath);
      fetchContents(currentPath);
      alert("Folder created successfully!");
    } catch (error) {
      console.error("Error creating folder:", error);
      alert("Failed to create folder.");
    }
  };

  const copyToClipboard = () => {
    if (selectedFile?.url) {
      navigator.clipboard.writeText(selectedFile.url);
      alert("URL copied to clipboard");
    }
  };

  if (error) return <p className="error">Error: {error}</p>;

  return (
    <div className="media-browser">

      <div className="upload-section">
        <button
          type="button"
          className="btn-link create-folder-link"
          onClick={handleCreateFolder}>
          📁 New Folder
        </button>
        <label htmlFor="file-upload" className="upload-label">
          📤 Upload Image
        </label>
        <input
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={handleFileUpload}
        />
      </div>

      <div className="media-browser-content">
        <div className="media-browser-folder-content">
          <div className="breadcrumb">
            <span className="path">Path:</span>
            <button
              type="button"
              className="btn-link breadcrumb-item"
              onClick={() => handlePathClick(-1)}>
              Top
            </button>
            {currentPath.split("/").filter(Boolean).map((folder, index) => (
              <button
                key={index}
                type="button"
                className="btn-link breadcrumb-item"
                onClick={() => handlePathClick(index)}
              >
                {folder}
              </button>
            ))}
          </div>

          <ul className="media-list">
            {currentPath && (
              <li className="media-item">
                <button
                  type="button"
                  className="btn-link parent-link"
                  onClick={() =>
                    handlePathClick(currentPath.split("/").filter(Boolean).length - 2)
                  }
                >
                  .. (Parent)
                </button>
              </li>
            )}
            {contents.map((item) => {
              const cleanName = item.name.replace(/\/$/, "");
              return (
                <li
                  key={cleanName}
                  className={`media-item ${selectedFile?.name === cleanName ? "selected" : ""}`}
                  onClick={() => item.type === "file" && handleFileClick(item)}
                >
                  {item.type === "folder" ? (
                    <button
                      type="button"
                      className="btn-link folder-link"
                      onClick={(e) => {
                        e.preventDefault();
                        handleFolderClick(cleanName);
                      }}
                    >
                      📁 {cleanName}
                    </button>
                  ) : (
                    <>
                      📄 {cleanName}
                      <button
                        className="delete-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(cleanName);
                        }}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
          {contents.length === 0 && (
            <p className="empty-folder">This folder is empty.</p>
          )}
        </div>
        {selectedFile && selectedFile.url && (
          <div className="file-preview">
            <h3>Preview</h3>
            <img
              src={selectedFile.url}
              alt={selectedFile.name}
              className="image-preview"
            />
            {mode === "standalone" && (
              <>
                <button
                  onClick={() => window.open(selectedFile.url, "_blank")}
                >
                  Open Full Image
                </button>
                <button onClick={copyToClipboard}>Copy URL</button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaBrowser;

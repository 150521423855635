import { useMsal } from "@azure/msal-react";
import { useMemo } from "react";
import { AuthenticatedService } from "../services/AuthenticatedService";

export const useService = <T extends AuthenticatedService>(
  ServiceClass: new (instance: any, accounts: any[]) => T
): T | null => {
  const { instance, accounts } = useMsal();

  const service = useMemo(() => {
    if (!instance || accounts.length === 0) {
      console.log("MSAL instance or accounts not initialized yet");
      return null;
    }
    console.log(`Initializing ${ServiceClass.name}`);
    return new ServiceClass(instance, accounts);
  }, [ServiceClass, instance, accounts]);

  return service;
};

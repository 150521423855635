import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import { Action, QuizGame } from "../types/QuizGame";
import Round369 from "../components/Round369";
import RoundOpenDeur from "../components/RoundOpenDeur";
import RoundPuzzel from "../components/RoundPuzzel";
import RoundGalerij from "../components/RoundGalerij";
import RoundCollectiefGeheugen from "../components/RoundCollectiefGeheugen";
import RoundFinale from "../components/RoundFinale";

const QuizGamePlayers: React.FC = () => {
  const { gameCode } = useParams<{ gameCode: string }>();
  const [game, setGame] = useState<QuizGame | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [timeOffset, setTimeOffset] = useState<number>(0);

  const socket = useRef<Socket | null>(null);

  useEffect(() => {
    if (!gameCode) return;

    if (!socket.current) {
      socket.current = io(process.env.REACT_APP_BACKEND_URL as string);
    }

    // console.log(`Players joining game ${gameCode}`);

    socket.current.emit("join-game", gameCode, (response: any) => {
      if (response.error) {
        console.error("Error joining game:", response.error);
        setError(response.error);
        return;
      }
      // console.log("Successfully joined game:", response.game);

      // Calculate server-client time difference
      const serverTime = response.serverTime || Date.now(); // Fallback if serverTime is not sent
      const clientTime = Date.now();
      const offset = serverTime - clientTime;

      // console.log("Time offset calculated:", offset);

      setTimeOffset(offset);

      setGame(response.game);
    });

    socket.current.on("game-state-changed", (updatedGame: QuizGame) => {
      setGame({
        ...updatedGame,
        activePlayerTimer_start: 
          updatedGame.activePlayerTimer_start === 0 ? 
            0 : updatedGame.activePlayerTimer_start + timeOffset,
      });
    });

    return () => {
      socket.current?.disconnect();
      socket.current = null;
    };
  }, [gameCode, timeOffset]);

    // Handle timer expiration by emitting "update-game"
    const handlePlayerTimeExpired = (playerIndex: number) => {
      if (!gameCode || !socket.current) return;
  
      const action: Action = {
        type: "timerEnds",
        targetIndex: playerIndex,
      };
  
      socket.current.emit("update-game", { gameCode, action }, (response: { error?: string }) => {
        if (response.error) {
          console.error("Error updating game:", response.error);
          setError(response.error);
        }
      });
    };
  

  if (error) return <p>Error: {error}</p>;
  if (!game) return <p>Loading...</p>;

  const isRound369 =
    game.activeRound === "369" &&
    game.quiz.round_369 !== undefined &&
    game.quiz.round_369.length > 0;

  const isRoundOpenDeur =
    game.activeRound === "open_deur" &&
    game.quiz.round_open_deur !== undefined &&
    game.quiz.round_open_deur.length > 0;

  const isRoundPuzzel =
    game.activeRound === "puzzel" &&
    game.quiz.round_puzzel !== undefined &&
    game.quiz.round_puzzel.length > 0;

  const isRoundGalerij =
    game.activeRound === "galerij" &&
    game.quiz.round_galerij !== undefined &&
    game.quiz.round_galerij.length > 0;

  const isRoundCollectiefGeheugen =
    game.activeRound === "collectief_geheugen" &&
    game.quiz.round_collectief_geheugen !== undefined &&
    game.quiz.round_collectief_geheugen.length > 0;

  const isRoundFinale =
    game.activeRound === "finale" &&
    game.quiz.round_finale !== undefined &&
    game.quiz.round_finale.length > 0;

  return (
    <div>
      {game.activeRound === undefined && (
        <div className="startscreen"></div>
      )
      }
      {isRound369 && (
        <Round369
          game={game}
        />
      )}
      {isRoundOpenDeur && (
        <RoundOpenDeur
          game={game}
        />
      )}
      {isRoundPuzzel && (
        <RoundPuzzel
          game={game}
        />
      )}
      {isRoundGalerij && (
        <RoundGalerij
          game={game}
        />
      )}
      {isRoundCollectiefGeheugen && (
        <RoundCollectiefGeheugen
          game={game}
        />
      )}
      {isRoundFinale && (
        <RoundFinale
          game={game}
          onPlayerTimeExpired={handlePlayerTimeExpired} // Pass the callback
        />
      )}
    </div>
  );
};

export default QuizGamePlayers;

import React, { useEffect, useState } from "react";
import "./RoundOpenDeurAdmin.css";
import { Question_OpenDeur } from "../types/Quiz";
import { Accordion } from "react-bootstrap";

const defaultQuestions: Question_OpenDeur[] = Array.from({ length: 3 }, (_, index) => ({
  id: index + 1,
  text: "",
  video: "",
  videoStartTime: 0,
  videoEndTime: 0,
  answers: ["", "", "", ""],
}));

interface RoundOpenDeurAdminProps {
  questions?: Question_OpenDeur[];
  onUpdateQuestions: (updatedQuestions: Question_OpenDeur[]) => void;
}

const RoundOpenDeurAdmin: React.FC<RoundOpenDeurAdminProps> = ({
  questions = defaultQuestions,
  onUpdateQuestions,
}) => {
  const [localQuestions, setLocalQuestions] = useState<Question_OpenDeur[]>([]);

  useEffect(() => {
    const adjustedQuestions = [...questions];
    if (adjustedQuestions.length < 3) {
      for (let i = adjustedQuestions.length; i < 3; i++) {
        adjustedQuestions.push({ ...defaultQuestions[i] });
      }
    } else if (adjustedQuestions.length > 3) {
      adjustedQuestions.length = 3;
    }
    setLocalQuestions(adjustedQuestions);
  }, [questions]);

  const handleUpdateQuestion = (
    id: number,
    updatedFields: Partial<Question_OpenDeur>
  ) => {
    const updatedQuestions = localQuestions.map((q) =>
      q.id === id ? { ...q, ...updatedFields } : q
    );
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  const handleUpdateAnswer = (questionId: number, answerIndex: number, value: string) => {
    const updatedQuestions = localQuestions.map((q) =>
      q.id === questionId
        ? {
            ...q,
            answers: q.answers.map((ans, idx) =>
              idx === answerIndex ? value : ans
            ),
          }
        : q
    );
    setLocalQuestions(updatedQuestions);
    onUpdateQuestions(updatedQuestions);
  };

  return (
    <div className="round-admin open-deur">
      <Accordion defaultActiveKey="0">
        {localQuestions.map((question, index) => (
          <Accordion.Item key={question.id} eventKey={index.toString()}>
            <Accordion.Header>Vraag {question.id}: {question.text}</Accordion.Header>
            <Accordion.Body>
              <label className="question-label">
                Vraag:
                <textarea
                  className="question-input"
                  value={question.text}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { text: e.target.value })
                  }
                />
              </label>
              <label className="question-label">
                YouTube Video ID:
                <input
                  type="text"
                  className="question-input"
                  value={question.video}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { video: e.target.value })
                  }
                />
              </label>
              <label className="question-label">
                Starttijd (seconden):
                <input
                  type="number"
                  className="question-input"
                  value={question.videoStartTime || 0}
                  onChange={(e) =>
                    handleUpdateQuestion(question.id, { videoStartTime: Number(e.target.value) })
                  }
                />
              </label>
              <label className="question-label">
                Duur (seconden):
                <input
                  type="number"
                  className="question-input"
                  value={(question.videoEndTime || 0) - (question.videoStartTime || 0)}
                  onChange={(e) => {
                    const duration = Number(e.target.value);
                    const endTime = duration > 0 ? (question.videoStartTime || 0) + duration : 0;
                    handleUpdateQuestion(question.id, { videoEndTime: endTime });
                  }}
                />
              </label>
              <div className="media-preview">
                {question.video ? (
                  <iframe
                    width="500"
                    height="315"
                    src={`https://www.youtube.com/embed/${question.video}?start=${question.videoStartTime || 0}&end=${question.videoEndTime ? question.videoEndTime : ""}`}
                    title="YouTube Video"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <div className="placeholder">Geen Media Beschikbaar</div>
                )}
              </div>
              <div className="answers-section">
                <label className="question-label">Antwoorden:</label>
                {question.answers.map((answer, answerIndex) => (
                  <div key={answerIndex} className="answer-input">
                    <input
                      type="text"
                      className="question-input"
                      value={answer}
                      placeholder={`Antwoord ${answerIndex + 1}`}
                      onChange={(e) =>
                        handleUpdateAnswer(question.id, answerIndex, e.target.value)
                      }
                    />
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default RoundOpenDeurAdmin;
